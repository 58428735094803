/* App.css */

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

table {
  width: 80%;
  margin: 20px 0;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #4CAF50;
  color: white;
}

input {
  padding: 5px;
  margin: 5px 0;
}

button {
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin: 0 15px;
  cursor: pointer;
}

.username-navbar{
  color: white;
  padding-top: 8px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  z-index: 1000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

/* Markdown */

.markdown-preview {
  background: #f6f8fa;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
}

pre {
  background: #f6f8fa;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

code {
  background: #f6f8fa;
  padding: 2px 4px;
  border-radius: 3px;
}

/* Champ de recherche */

.search-input {
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.thumbnail-image {
  width: 50px;
  height: auto;
  border-radius: 5px;
}

/* Styles pour les liens actifs */
.nav-link.active {
  font-weight: bold;
}
